<!--  -->
<template>
	<div class="app-container" style="padding: 10px 0px 20px">
		<div class="fr" style="margin: -10px 0 10px">
			<el-button type="primary" size="small" icon="el-icon-plus" style="min-width: 72px; margin-bottom: 10px" @click="handleAdd"
				>添加</el-button
			>
		</div>
		<el-table :data="tableList" v-loading="loadding" style="width: 100%" border stripe>
			<el-table-column label="地址名称" align="center" prop="addressName"></el-table-column>

			<el-table-column label="联系人" align="center" prop="name"></el-table-column>
			<el-table-column label="联系电话" align="center" prop="phoneNum"></el-table-column>
			<el-table-column label="详细地址" align="center" prop="detailedAddress"></el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑 </el-button>
					<el-button size="mini" type="text" style="color: #f56c6c" @click="handleDel(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			class="fr"
			v-if="table.total > 20"
			style="margin: 20px 0"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.total"
		></el-pagination>

		<!-- 新增编辑弹框 -->
		<el-dialog width="500px" center :title="formTitle" :visible.sync="dialogVisible" :before-close="handleClose" @open="openDialog">
			<el-form :model="formModel" :rules="rules" ref="dialogForm" label-width="88px" class="demo-ruleForm">
				<el-form-item label="地址名称" prop="addressName">
					<el-input v-model="formModel.addressName" maxlength="50"></el-input>
					<!-- <el-cascader :options="cityOptions"
                       v-model="selectedCity"
                       separator=" "
                       filterable
                       clearable
                       style="width:100%;"></el-cascader> -->
				</el-form-item>
				<el-form-item label="联系人" prop="name">
					<el-input v-model="formModel.name" maxlength="10"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phoneNum">
					<el-input v-model="formModel.phoneNum" maxlength="15"></el-input>
				</el-form-item>
				<el-form-item label="详细地址" prop="detailedAddress">
					<el-input type="textarea" maxlength="50" v-model="formModel.detailedAddress" show-word-limit :rows="2"></el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="cancle">取 消</el-button>
				<el-button type="primary" @click="commitForm" :loading="loading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		const validateUsername = (rule, value, callback) => {
			const re = /^\d{3,11}$|^\d{3,11}$/;
			// var re = /^[1][3-8]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/;

			if (value === '') {
				callback(new Error('请输入手机号码'));
			} else if (!re.test(value)) {
				callback(new Error('请输入正确手机号码'));
			}
			callback();
		};

		return {
			loading: false,
			rules: {
				addressName: [
					{ required: true, type: 'string', message: '请输入地址名称', trigger: 'blur' },
					{ min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
				],
				name: [
					{ required: true, type: 'string', message: '请输入联系人姓名', trigger: 'blur' },
					{ min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
				],
				phoneNum: [{ required: true, type: 'string', validator: validateUsername }],
				detailedAddress: [{ required: true, type: 'string', message: '请输入详细地址', trigger: 'blur' }]
			},
			loadding: false,
			tableList: [],
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			formTitle: '',
			dialogVisible: false,
			formModel: {}
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		openDialog() {
			//   if (this.cityOptions.length === 0) {
			//     this.getCityList();
			//   }
			this.$nextTick(() => {
				this.$refs.dialogForm.clearValidate();
			});
		},
		// 获取城市
		// getCityList () {
		//   this.cityOptions = window.$cityList
		// },
		getList() {
			this.loadding = true;
			this.$axios
				.post('/v1/recruit/config/address/list', {
					request: {
						pageNo: this.table.pageNo,
						pageSize: this.table.pageSize
					},
					sign: ''
				})
				.then((res) => {
					this.tableList = res.list;
					this.table.total = res.totalNum;
					this.loadding = false;
				});
		},
		cancle() {
			this.handleClose();
		},
		handleClose() {
			this.dialogVisible = false;
			this.$nextTick(() => {
				this.$refs.dialogForm.clearValidate();
				this.$refs.dialogForm.resetFields(); // this.$refs.adduserform.resetFields();
			});
		},
		handleAdd() {
			this.formModel = {};
			this.formTitle = '添加地址信息';
			//   this.$refs.dialogForm.resetFields();
			this.dialogVisible = true;
		},
		handleEdit(row) {
			this.formTitle = '编辑地址信息';
			//   this.$refs.dialogForm.resetFields();
			this.dialogVisible = true;
			this.formModel = {
				id: row.id,
				addressName: row.addressName,
				name: row.name,
				phoneNum: row.phoneNum,
				detailedAddress: row.detailedAddress
			};
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/recruit/config/address/delete', {
						request: { id: Number(row.id) },
						sign: ''
					})
					.then((res) => {
						if (res && res.code == 0) {
							this.$message.success('删除成功!');
							this.getList();
						}
					});
			});
		},
		// 提交新增
		commitForm() {
			this.$refs.dialogForm.validate((valid) => {
				if (valid) {
					this.loading = true;
					this.$axios
						.post('/v1/recruit/config/address/save', {
							request: this.formModel,
							sign: ''
						})
						.then((res) => {
							this.loading = false;
							if (res && res._responseStatusCode == 0) {
								this.$message.success(this.formTitle == '添加地址信息' ? '添加成功' : '编辑成功');
								this.handleClose();
								this.getList();
							}
						});
				} else {
					return false;
				}
			});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},

		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
