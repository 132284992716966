<!--  -->
<template>
  <div class='emailsetting'>
    <div>
      <div class="save_btn">
        <div class="header_title">自动处理管理</div>
        <el-button type="primary"
                   size="small"
                   icon="el-icon-finished"
                   @click="onSave()"><span>保存</span></el-button>
      </div>

      <div style="padding:24px 0 24px 40px;border-bottom: 1px solid #ebebeb;">
        <!-- <div class="manage_box">
          <span style="width:180px;display:inline-block;text-align: right;">自动放弃"未处理"状态简历</span>
          <el-switch style="margin:0 12px;"
                     @change="switchStatus()"
                     active-value="0"
                     inactive-value="1"
                     v-model="autoList[0].open"></el-switch>
          <span class="span_box" style="width:158px">"未处理"状态简历，超过</span>
          <el-input class="input_box span_box"
                    onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    v-model="autoList[0].day"
                    size="small"
                    type="number">
          </el-input>
          <span>天之后，移入到</span>
          <span class="border_box">"人才库-已淘汰"</span>
        </div> -->
        <div class="manage_box">
          <span style="width:180px;display:inline-block;text-align: right;">发送offer再入职</span>
          <el-switch style="margin:0 12px;"
                     @change="switchStatus()"
                     active-value="0"
                     inactive-value="1"
                     v-model="autoList[2].open"></el-switch>
          <span class="span_box">"发送offer"入职时间开始</span>
          <el-input-number class="input_box span_box"
                    onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    v-model="autoList[2].day"
                    size="small"
                    :controls="false"
                    :max="5"
                    :min="1"
                    type="number">
          </el-input-number>
          <span>天之内</span>
          <span class="border_box">"可再次入职"</span>
        </div>
                <div class="manage_box">
          <span style="width:180px;display:inline-block;text-align: right">发送offer再申请入职</span>
          <el-switch style="margin:0 12px;"
                     @change="switchStatus()"
                     active-value="0"
                     inactive-value="1"
                     v-model="autoList[1].open"></el-switch>
          <span class="span_box">"发送offer"入职时间开始</span>
          <el-input-number class="input_box span_box"
                    onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    v-model="autoList[1].day"
                    :max="6"
                    :min="autoList[2].day"
                    size="small"
                    :controls="false"
                    type="number">
          </el-input-number>
          <span style="margin-right: 10px;">至</span>
          <el-input-number class="input_box span_box"
                    onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    v-model="autoList[1].endDay"
                    size="small"
                    :max="30"
                    :min="autoList[1].day+1"
                    :controls="false"
                    type="number">
          </el-input-number>
          <span>天</span>
          <span class="border_box">"可再申请入职"</span>
        </div>
      </div>
    </div>

    <!-- 邮件模板管理 !-->
    <div>
      <div class="save_btn" style="margin-top: 15px;">
        <div class="header_title">邮件模板管理</div>
        <el-button type="primary"
                   size="small"
                   icon="el-icon-finished"
                   @click="onSubmit()"><span>保存</span></el-button>
      </div>
      <div style="padding-left: 40px;">
        <div class="email_box">
          <el-tag v-for="(item,index) in emailList"
                  :key="index"
                  :disable-transitions="false"
                  :effect="emailNameId == item.id? 'dark': 'plain'"
                  @click="emailNameClick(item,index)">
            {{item.emailName}}
          </el-tag>
          <el-button class="button-new-tag"
                     size="small"
                     :type="emailNameId=='add'?'primary':''"
                     @click="addModel">+ 添加模板</el-button>
        </div>
        <el-form :model="emailModel"
                 :rules="rules"
                 ref="emailForm"
                 label-position="left"
                 label-width="80px"
                 class="demo-ruleForm">
          <el-form-item label="模板类型"
                        prop="emailType"
                        class="formLabel"
                        style="margin-top:24px;">
            <el-radio-group v-model="emailModel.emailType">
              <el-radio v-for="(item,i) in emailTypeList" :key="i" :label="item.dictId">
                {{item.dictName}}
              </el-radio>
            </el-radio-group>

          </el-form-item>
          <el-form-item label="模板名称"
                        prop="emailName"
                        class="formLabel"
                        style="margin-top:24px;">
            <el-input v-model="emailModel.emailName" maxlength="10" show-word-limit style="width:300px;"></el-input>
          </el-form-item>
          <el-form-item label="邮件标题"
                        prop="emailTitle"
                        class="formLabel"
                        style="margin-top:24px;">
            <el-input v-model="emailModel.emailTitle" maxlength="10" show-word-limit style="width:300px;"></el-input>
          </el-form-item>
        </el-form>
        <div class="email_box">
           <el-tag v-for="(tag,index) in emailTypeTagList[emailModel.emailType] ? emailTypeTagList[emailModel.emailType].dictName.split(',') : []" :key="index" @click="enterTag(tag)">{{tag}}</el-tag>
        </div>
        <ck-editor v-model="emailModel.emailBody"
                   ref="myEditor"
                   style="margin-top:24px;"></ck-editor>
      </div>
    </div>
  </div>
</template>

<script>
import CkEditor from '@/components/CkEditor';
export default {
  components: { CkEditor },
  data () {
    return {
      status: '2',
      dayValue: '',
      activeName: '0',
      handleList: [],
      emailList: [],
      emailTypeList: [],
      emailTypeTagList: [],
      emailNameId: '', // 当前选中的邮件名称id
      autoList: [
        {
          open: '1',
          day: '1',
          status: '10',
          type: '1'
        },
        {
          open: '1',
          day: '1',
          endDay: '1',
          status: '1',
          type: '2'
        },
        {
          open: '1',
          day: '1',
          status: '1',
          type: '3'
        }
      ],
      emailModel: {
        id: '',
        emailType: '',
        emailName: '',
        emailTitle: '',
        emailBody: ''
      },
      modelCopy: {
        id: '',
        emailType: '',
        emailName: '',
        emailTitle: '',
        emailBody: ''
      },
      tags: [
        { name: '标签一', type: '' },
        { name: '标签二', type: 'success' },
        { name: '标签三', type: 'info' },
        { name: '标签四', type: 'warning' },
        { name: '标签五', type: 'danger' }
      ],

      rules: {
        emailName: [
          { required: true, type: 'string', message: '请输入邮件模板名称', trigger: 'blur' }
          //   { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        emailTitle: [
          { required: true, type: 'string', message: '请输入邮件模板标题', trigger: 'blur' }
          //   { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ],
        emailType: [
          { required: true, type: 'string', message: '请选择邮件模板类型', trigger: 'change' }
          //   { min: 1, max: 30, message: "长度在 1 到 30 个字符", trigger: "blur" }
        ]
      }
    };
  },
  mounted () {
    this.getHandleList();
    this.getEmailList();
  },
  created() {
      this.emailTypeList = this.$store.state.app.dict.filter((item) => item.groupId == 'emailType');
      this.emailTypeTagList = this.$store.state.app.dict.filter((item) => item.groupId == 'emailTypeTag');
  },
  methods: {
    switchStatus () {

    },
    enterTag(tag) {
      window.CKEDITOR.instances.ckEditor.insertText(`{#${tag}}`);
      this.emailModel.emailBody = window.CKEDITOR.instances.ckEditor.getData();
    },
    tabClick () { },
    getHandleList () {
      this.$axios
        .post('/v1/recruit/config/automatic/list', {
          request: {
          },
          sign: ''
        })
        .then((res) => {
          if (res && res.resumeAutomaticListDtoList && res.resumeAutomaticListDtoList.length > 0) {
            this.autoList = res.resumeAutomaticListDtoList.sort((a, b) => a.type - b.type) || [];
          }

          //   this.boxList = res.data
        });
    },
    // 自动管理保存
    onSave () {
      const isPass = this.autoList.find((item) => item.day == 0 || !item.day);

      if (isPass) {
        return this.$message.error('请输入大于0的天数');
      }
      if (this.autoList[2].day > this.autoList[1].day && this.autoList[1].open == 0) {
        return this.$message.error('再入职截止时间不能晚于再入职申请时间');
      }
      if (Number(this.autoList[1].day) >= Number(this.autoList[1].endDay) && this.autoList[1].open == 0) {
        return this.$message.error('前后时间不能重合');
      }
      if (this.autoList[2].day >= (Number(this.autoList[1].day) + 1) && this.autoList[1].open == 0) {
        return this.$message.error('再入职申请为再入职时间的后一天');
      }
      this.autoList[0].status = 10;
      this.$axios
        .post('/v1/recruit/config/automatic/save', {
          request: {
            resumeAutomaticSaveDtoList: this.autoList
          },
          sign: ''
        })
        .then((res) => {
          if (res.code == 0) {
            this.getHandleList();
            this.$message.success('保存成功');
          }
        });
    },
    getEmailList () {
      this.$axios
        .post('/v1/recruit/config/email/list', {
          request: {
            id: ''
          },
          sign: ''
        })
        .then((res) => {
          if (res && res.emailLisDtoList && res.emailLisDtoList.length > 0) {
            this.emailList = res.emailLisDtoList || [];
            this.emailNameId = res.emailLisDtoList[0].id;
            this.emailModel = JSON.parse(JSON.stringify(res.emailLisDtoList[0]));
            this.modelCopy = JSON.parse(JSON.stringify(res.emailLisDtoList[0]));
            this.$refs.myEditor.setContent(this.emailList[0].emailBody);
            // console.log("this.emailModel", this.emailModel)
          }

          //   this.boxList = res.data
        });
    },
    // 切换模板
    emailNameClick (item) {
      this.$refs.emailForm.clearValidate();
      this.emailNameId = item.id;
      this.emailModel = JSON.parse(JSON.stringify(item));
      this.modelCopy = JSON.parse(JSON.stringify(item)); // 点击取消时使用
      //   console.log(this.emailModel, this.emailList)
      //   console.log("ref", this.$refs.myEditor)
      this.$refs.myEditor.setContent(item.emailBody);
    },
    // 模板保存
    onSubmit () {
      if (!this.emailModel.emailBody) {
        return this.$message.error('请填写模板内容');
      }
      this.$refs.emailForm.validate((valid) => {
        if (valid) {
          this.emailModel.emailBodyId = this.emailModel.emailBody;

          if (this.emailTypeTagList[this.emailModel.emailType]) {
            this.emailTypeTagList[this.emailModel.emailType].dictName.split(',').map((it, i) => {
              if (this.emailModel.emailBodyId.search(it) != -1) {
                this.emailModel.emailBodyId = this.emailModel.emailBodyId.replace(it, i);
              }
            });
          }
          this.$axios.post('/v1/recruit/config/email/save', {
              request: this.emailModel,
              sign: ''
            })
            .then((res) => {
              if (res.code == 0) {
                this.getEmailList();
                // if (this.emailList.length > 0) {
                this.$refs.myEditor.setContent(this.emailList[0].emailBody);
                // }
                this.$message.success('保存成功');
              }
            });
        }
      });
    },
    addModel () {
      this.$refs.emailForm.clearValidate();
      this.emailNameId = 'add';
      this.emailModel = {id: '',
        emailType: '0',
        emailName: '',
        emailTitle: '',
        emailBody: '' };
      this.$refs.myEditor.setContent('');
    },
    cancelEdit () {
      this.emailModel = JSON.parse(JSON.stringify(this.modelCopy));
      this.$refs.myEditor.setContent(this.emailModel.emailBody);
    }
  }
};
</script>
<style lang='scss'>
.emailsetting {
  label {
    font-weight: normal;
  }
  .header_title {
    color: #303133;
    font-size: 16px;
    font-weight: bold;
    padding: 0px 15px;
    height: 48px;
    line-height: 48px;
  }
  .manage_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #484848;
    margin-bottom: 12px;
    .span_box {
      margin-right: 12px;
      display: inline-block;
      text-align: right;
    }
  }
  .input_box {
    width: 80px;
  }
  .email_box {
    .el-tag + .el-tag {
      margin-left: 10px;
    }
    .el-tag {
      min-width: 80px;
      text-align: center;
      cursor: pointer;
    }

    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .border_box {
    border: 1px solid #DCDFE6;
    padding: 6px 10px;
    margin-left: 10px;
    border-radius: 4px;
  }
  .bottom_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .save_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
.header_title{
  &:before {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #498df0;
      content: " ";
      border-radius: 5px;
      vertical-align: sub;
      margin-right: 8px;
    }
}
</style>
