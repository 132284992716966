<!--  -->
<template>
  <div class='tag_manage'>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <div class="titleIcon"></div>
          标签管理
        </template>
        <!-- <div class="tag_box">
          <span class="tag_item"
                v-for="(item,index) in tagList"
                :key="index">{{item.tagName}}</span>
          <span class="tag_item">+</span>
        </div> -->
        <div class="tagList_box">
          <el-tag v-for="item in tagList"
                  :key="item.id"
                  effect="dark"
                  closable
                  @close="tagClose(item,'tag')">
            {{ item.tagName }}
          </el-tag>
          <el-input class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="small"
                    @keyup.enter.native="tagAdd"
                    maxlength="10"
                    @blur="tagAdd">
          </el-input>
          <el-button v-else
                     class="button-new-tag"
                     size="small"
                     @click="showInput('tag')">+ 新增标签</el-button>
        </div>
      </el-collapse-item>

      <el-collapse-item name="2">

        <template slot="title">
          <div class="titleIcon"></div>
          落选/移库
        </template>
        <div class="tag_box_2">
          <el-collapse v-model="classfiyActive">
            <!-- 分类标签名称 -->
            <el-collapse-item v-for="(item,index) in classifyList"
                              :key="item.classifyId"
                              :name="item.classifyId">

              <template slot="title">
                <!-- <div class="titleIcon"></div> -->

                <el-input class="edit_input"
                          autofocus
                          v-if="isEdit&&classifyId==item.classifyId"
                          v-model="classfiyTagValue"
                          ref="titleInput"
                          size="small"
                          maxlength="10"
                          @keyup.enter.native="classfiyTagAdd(index,'edit')"
                          @blur="classfiyTagAdd(index,'edit')">
                </el-input>
                <span v-else>{{item.classifyName}}</span>
                <img v-if="!isEdit" style="vertical-align:middle;margin-left: 10px;" width="20px" src="../../../assets/button/编辑@3x.png" alt="" @click.stop="edit( $event,item)">
              </template>
              <!-- 分类子标签 -->
              <div class="tagList_box">
                <el-tag v-for="children in item.list"
                        :key="children.id"
                        effect="dark"
                        closable
                        @close="tagClose(children,'classify')">
                  {{ children.tagName }}
                </el-tag>
                <el-input class="input-new-tag"
                          v-if="classfiyInputVisible&&classifyId==item.classifyId"
                          v-model="classfiyTagValue"
                          ref="saveClassfiyTagInput"
                          size="small"
                          maxlength="10"
                          @keyup.enter.native="classfiyTagAdd(index)"
                          @blur="classfiyTagAdd(index)">
                </el-input>
                <el-button v-else
                           class="button-new-tag"
                           size="small"
                           @click="showInput('classify',index,item.classifyId)">+ 新增标签</el-button>
              </div>
              <!-- <div class="tag_box">
                <span class="tag_item">腾讯</span>
                <span class="tag_item">阿里巴巴</span>
                <span class="tag_item">+</span>
              </div> -->
            </el-collapse-item>

            <!-- <el-collapse-item name="2">

              <template slot="title">
                <div class="titleIcon"></div>
                人才库-人才储备
              </template>
              <div class="tag_box">
                <span class="tag_item">腾讯</span>
                <span class="tag_item">阿里巴巴</span>
                <span class="tag_item">+</span>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">

              <template slot="title">
                <div class="titleIcon"></div>
                人才库-黑名单
              </template>
              <div class="tag_box">
                <span class="tag_item">腾讯</span>
                <span class="tag_item">阿里巴巴</span>
                <span class="tag_item">+</span>
              </div>
            </el-collapse-item> -->
          </el-collapse>

        </div>
      </el-collapse-item>

    </el-collapse>

  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      tagList: [],
      classifyList: [],
      inputVisible: false,
      inputValue: '',
      classfiyTagValue: '',
      classfiyActive: [],
      classfiyInputVisible: false,
      activeNames: ['1', '2'],
      classifyId: '',
      isEdit: false
    };
  },
  mounted () {
    this.getClassfiyList();
    this.getTagList();
  },
  methods: {
    getTagList () {
      this.$axios
        .post('/v1/tag/list', {
          request: {
            id: '',
            tagPid: '',
            tagType: ''
          },
          sign: ''
        })
        .then((res) => {
          if (res) {
            this.tagList = res;
          }
        });
    },
    getClassfiyList () {
      this.$axios
        .post('/v1/tag/classify/list', {
          request: {},
          sign: ''
        })
        .then((res) => {
          if (res) {
            this.classifyList = res;
            res.map((it) => {
              this.classfiyActive.push(it.classifyId);
            });
          }
        });
    },
    tagClose (item) {
      this.$confirm('是否确定移除该标签?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        this.$axios
          .post('/v1/tag/delete', {
            request: { id: item.id },
            sign: ''
          })
          .then((res) => {
            if (res && res.code == 0) {
              this.$message.success('移除成功!');
                this.getTagList();
                this.getClassfiyList();
            }
          });
      });
    },
    showInput (name, index, classifyId) {
      // console.log('showInput', name)
      if (name == 'tag') {
        this.inputVisible = true;
        this.$nextTick(() => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      } else if (name == 'classify') {
        this.classifyId = classifyId;
        this.classfiyInputVisible = true;
        this.$nextTick(() => {
          //   console.log("saveClassfiyTagInput", index, this.$refs.saveClassfiyTagInput)
          this.$refs.saveClassfiyTagInput[0].$refs.input.focus();
        });
      }

    },
    edit (e, item) {
      e.preventDefault();
      this.classifyId = item.classifyId;
      this.classfiyTagValue = item.classifyName;
    //   this.$nextTick(() => {
    //     // console.log("isfous", isfous.titleInput)
    //     // console.log("000", this.$refs['titleInput'])
    //     //   this.$refs.titleInput[0].$refs.input.focus();
    //   });
      this.isEdit = true;
    },
    classfiyTagAdd (index, name) {
      const classIndex = index ? index : 0;
        const {classfiyTagValue} = this;
        const arr = [10, 11, 12];

      console.log(classfiyTagValue);
      if (classfiyTagValue) {
        this.$axios
          .post('/v1/tag/save', {
            request: {
              id: name == 'edit' ? this.classifyId : '',
              tagPid: this.classifyId, // 标签管理tagPid = 1 分类标签：0
              tagName: classfiyTagValue,
              tagType: arr[classIndex] // 10-已淘汰 11-人才储备 12-黑名单
            },
            sign: ''
          })
          .then((res) => {
            if (res && res.code == 0) {
              this.getClassfiyList();
              this.getTagList();
            }
          });
      }
      this.classfiyInputVisible = false;
      this.isEdit = false;
      this.classfiyTagValue = '';
    },
    tagAdd () {
      const {inputValue} = this;

      if (inputValue) {
        this.$axios
          .post('/v1/tag/save', {
            request: {
              id: '',
              tagPid: '1', // 标签管理tagPid = 1 分类标签：0
              tagName: inputValue,
              tagType: '1' // 10-已淘汰 11-人才储备 12-黑名单
            },
            sign: ''
          })
          .then((res) => {
            if (res && res.code == 0) {
              //   this.$message.success('移除成功!')
              this.getClassfiyList();
              this.getTagList();
            }
          });
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }
};
</script>
<style lang="scss">
.tag_box_2 {
  .el-collapse .el-collapse-item__header {
    padding: 0px 15px;
    // height: 75px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item {
    border-bottom: 1px solid #EBEEF5;
  }
}
.tag_manage {
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__nav {
    float: none;
    text-align: center;
    background: #f6f7fb;
    padding: 10px 0px;
  }

  .el-tabs__item {
    font-size: 18px;
    font-weight: bold;
  }

   .el-collapse {
    border-top: none;
    border-bottom: none;
    margin-bottom: 10px;
  }

  .el-collapse-item__header {
    border-bottom: none;
    font-size: 16px;
    font-weight: bold;
    padding: 0px 15px;
    //  height: 75px;
  }
}
</style>
<style lang='scss' scoped>
.titleIcon {
  display: inline-block;
  width: 8px !important;

  height: 20px;

  background: #498df0;

  border-radius: 5px;

  vertical-align: bottom;

  margin-right: 5px !important;
}
.tagList_box {
  .el-tag {
    margin-left: 24px;
    margin-bottom: 24px;
  }
  .button-new-tag {
    margin-left: 24px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    min-width: 90px;
    max-width: 200px;
    margin-left: 24px;
    // vertical-align: bottom;
  }
}
.tag_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-left: 30px;
  .tag_item {
    height: 40px;
    min-width: 100px;
    padding: 0 10px;
    border-radius: 20px;
    background-color: #498df0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
}
.edit_input {
  min-width: 90px;
  max-width: 200px;
  margin-left: 24px;
}
</style>
