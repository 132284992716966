<!--  -->
<template>
  <div>
    <el-row style="text-align: right;margin-bottom: 20px;">
      <el-button type="primary" size="small" @click="handleAdd" icon="el-icon-plus">添加</el-button>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in boxList" :key="index" style="margin-bottom: 20px;">
        <div class="mcol">
          <div class="list_box">
            <span class="item_name">{{item.interviewRoundsName}}</span>
            <el-row class="mcol_top">
              <el-switch
                @change="switchStatus(item.id)"
                active-value="2"
                inactive-value="1"
                v-model="item.status"
              ></el-switch>
              <img style="margin: 0 11px;vertical-align:middle;" width="20px" src="../../../assets/button/编辑@3x.png" alt="" @click="handleEdit(item)">
              <img style="vertical-align:middle;" width="20px" src="../../../assets/button/删除@3x.png" alt="" @click="handleDel(item)">
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 新增编辑弹框 -->
    <el-dialog
      width="500px"
      center
      :title="formTitle"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      @open="openDialog"
    >
      <el-form
        :model="formModel"
        :rules="rules"
        ref="dialogForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="面试轮次" prop="interviewRoundsName">
          <el-input v-model="formModel.interviewRoundsName" maxlength="10"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="commitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  configRoundList,
  configRoundDelete,
  configRoundSave,
  configRoundStatusChange
} from '@/api/recruitment_allocation.js';
export default {
  components: {},
  data() {
    return {
      rules: {
        interviewRoundsName: [
          {
            required: true,
            type: 'string',
            message: '请输入面试轮次',
            trigger: 'blur'
          },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ]
      },
      boxList: [],
      formTitle: '',
      dialogVisible: false,
      formModel: {}
    };
  },
  mounted() {
    this._getList();
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate();
      });
    },
    _getList() {
      // "status": "1",开关状态 1-禁用 2-启动
      configRoundList({}).then((res) => {
        if (res) {
          this.boxList = res;
        }
      });
    },
    switchStatus(id) {
      configRoundStatusChange({ id }).then((res) => {
        if (res.code == 0) {
          this._getList();
        }
      });
    },
    cancle() {
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs.dialogForm.clearValidate();
        this.$refs.dialogForm.resetFields();
      });
    },
    handleAdd() {
      this.formModel = {};
      this.formTitle = '添加面试轮次';
      this.dialogVisible = true;
    },
    handleEdit(row) {
      this.formTitle = '编辑面试轮次';
      this.dialogVisible = true;
      this.formModel = {
        id: row.id,
        interviewRoundsName: row.interviewRoundsName
      };
    },
    handleDel(row) {
      this.$confirm('是否确定删除?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        configRoundDelete({
          id: row.id,
          interviewRoundsName: row.interviewRoundsName
        }).then((res) => {
          if (res && res.code == 0) {
            this.$message.success('删除成功!');
            this._getList();
          }
        });
      });
    },
    // 提交新增
    commitForm() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          configRoundSave(this.formModel).then((res) => {
            if (res && res.code == 0) {
              this.$message.success(
                this.formTitle == '添加面试轮次' ? '添加成功' : '编辑成功'
              );
              this.handleClose();
              this._getList();
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang='scss' scoped>
.mcol {
  padding: 20px 10px;
  border: 1px solid #c5cdda;
  border-radius: 5px;
  position: relative;
  .list_box {
    display: flex;
    flex-direction: row;
    .item_name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #636363;
      letter-spacing: -0.28px;
      flex: 1;
    }
    .mcol_top {
      i {
        cursor: pointer;
        font-size: 18px;
        vertical-align: middle;
      }
      .el-icon-edit-outline {
        color: #409eff;
        margin: 0 5px;
      }
      .el-icon-delete {
        color: #f56c6c;
      }
    }
  }
}
</style>
