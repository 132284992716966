<!--  -->
<template>
  <div class="app-container recruitmentsettings">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="标签管理" name="0">
        <tag-manage></tag-manage>
      </el-tab-pane>
      <el-tab-pane label="面试轮次管理"
                   name="1">
        <interview-manage></interview-manage>
      </el-tab-pane>
      <el-tab-pane label="地址管理"
                   name="2">
        <address-manage></address-manage>
      </el-tab-pane>
      <el-tab-pane label="设置管理"
                   name="3">
        <setting-manage></setting-manage>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import addressManage from './addressManage.vue';
import interviewManage from './interviewManage.vue';
import tagManage from './tagManage.vue';
import settingManage from './settingManage.vue';
export default {
  components: {
    addressManage,
    interviewManage,
    tagManage,
    settingManage
  },
  data () {
    return {
      activeName: '0'
    };
  },
  mounted () {

  },
  methods: {
    tabClick () {}
  }
};
</script>
<style lang='scss'>
  .recruitmentsettings .el-tabs__header {
    margin-bottom: 20px;
  }
</style>
